export const TASK_CREATE_TS_ATTR = 'create_ts'
export const TASK_CRITERION_CONTROL_LOCATION_ATTR = 'criterion_control_location'
export const TASK_CRITERION_CONTROL_OBJECT_ATTR = 'criterion_control_object'
export const TASK_CRITERION_NAME_ATTR = 'criterion_name'
export const TASK_CRITERION_TOOLS_ATTR = 'criterion_tools'
export const TASK_CRITERION_PERIOD_ATTR = 'criterion_period'
export const TASK_CRITERION_TYPES_ATTR = 'criterion_types'
export const TASK_CRITERION_ATTENDANT_ATTR = 'criterion_attendant'
export const TASK_CRITERION_LEVEL2_ATTR = 'level2_criterion_name'
export const TASK_CRITERION_PATH_ATTR = 'criterion_path'
export const TASK_DUE_TO_TS_ATTR = 'due_to_ts'
export const TASK_ID_ATTR = 'id'
export const TASK_STATUS_ATTR = 'status'
export const TASK_UPDATE_TS_ATTR = 'update_ts'
export const TASK_RESPONSE_OPTIONS_ATTR = 'response_options'
export const TASK_HOUSE_ENTITY_ATTR = 'house_entity'
export const TASK_HOUSE_ENTITY_DESCRIPTION_ATTR = 'house_entity_summary'
export const TASK_HOUSE_ENTITY_HOUSE_ATTR = 'house_entity.house'
export const TASK_HOUSE_ENTITY_TYPE_NAME_ATTR = 'house_entity.house_entity_type.name'

export const TASK_STATUS_DISABLED = 'Все'
export const TASK_STATUS_NEW = 'Не выполнено'
export const TASK_STATUS_NEED_HELP = 'Нужна помощь'
export const TASK_STATUS_COMPLETE = 'Выполнено'

export const TASKS_PER_PAGE = 10
export const DATE_FORMAT = 'dd.MM.yy'

export const FILTER_ATTRS = [
    { value: TASK_STATUS_ATTR, label:'Статус' },
    { value: TASK_CRITERION_CONTROL_LOCATION_ATTR, label:'Где проверить' },
    { value: TASK_CRITERION_CONTROL_OBJECT_ATTR, label:'Объект проверки' },
    { value: TASK_CRITERION_PERIOD_ATTR, label:'Период проверки' },
    { value: TASK_CRITERION_TYPES_ATTR, label:'Тип критерия' },
    { value: TASK_HOUSE_ENTITY_TYPE_NAME_ATTR, label:'Тип места проверки' },
    { value: TASK_CRITERION_TOOLS_ATTR, label:'Инструменты' },
    { value: TASK_CRITERION_ATTENDANT_ATTR, label:'Требуется представитель УК' },
    { value: TASK_CRITERION_LEVEL2_ATTR, label:'Группы критериев' },
    { value: TASK_HOUSE_ENTITY_DESCRIPTION_ATTR, label:'Место проверки' },
    { value: TASK_CRITERION_PATH_ATTR, label:'Индекс критерия' },
]
