import {
    configureStore,
    combineReducers,
} from '@reduxjs/toolkit'
import authSlice from "./components/core/auth/authSlice"
import checkListSlice from "./components/features/check_list/checkListSlice"
import taskSlice from "./components/common/task/taskSlice"

const combinedReducer = combineReducers({
    auth: authSlice,
    task: taskSlice,
    checkList: checkListSlice,
})

const get_middleware = getDefaultMiddleware => {
    let middleware = getDefaultMiddleware()
    if (process.env.NODE_ENV !== 'production') {
        const { createLogger } = require('redux-logger')
        const logger = createLogger()
        middleware.push(logger)
    }
    return middleware
}

const rootReducer = (state, action) => {
    if (action.type === 'auth/logoutSuccess') {
        state = undefined
    }
    return combinedReducer(state, action)
}

export default configureStore({
    reducer: rootReducer,
    middleware: get_middleware,
    devTools: process.env.NODE_ENV !== 'production',
})
