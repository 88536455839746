import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material"
import { useSelector } from "react-redux"
import { ROUTE_URL_HOME } from "../../../routes/constants"
import { useNavigate } from "react-router-dom"
import HomeIcon from '@mui/icons-material/Home'


// FIXME: привести нейминг к бэку. Например CheckList -> tasks
const Header = ({
    onIconClick,
    icon = <HomeIcon/>,
}) => {
    const authenticatedUserName = useSelector( state => state.auth.authenticatedUserName )
    const navigate = useNavigate()

    /* FIXME: не универсальный вариант навигации, но нам другой пока и не нужен*/
    const defaultOnIconClick = () => navigate(ROUTE_URL_HOME)
    return (
        <AppBar>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <IconButton onClick={ onIconClick || defaultOnIconClick }>
                        { icon }
                    </IconButton>
                </Box>
                <Typography variant="h6">Привет, { authenticatedUserName }!</Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Header
