import { Fragment } from "react"
import { Button, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { TASK_META_PARAMS } from "../constants"
import { NO_DATA_STR } from "../../../../utils/constants"
import { getNestedValue } from "../../../../utils/utils"
import {
    TASK_CRITERION_NAME_ATTR,
    TASK_CRITERION_PATH_ATTR,
    TASK_STATUS_ATTR,
} from "../../../features/check_list/constants"


const TaskHeader = ({
    long,
    lat,
    curLon,
    curLat,
    geoError,
    checkListTaskMeta,
    measureDateTime,
}) => {
    const measureAddress = `https://yandex.ru/maps/?pt=${long},${lat}&z=18&l=map`
    const userAddress = `https://yandex.ru/maps/?pt=${curLon},${curLat}&z=18&l=map`
    const criterionPathWiki = getNestedValue(checkListTaskMeta, TASK_CRITERION_PATH_ATTR)
    const criterionPathWikiUrl = criterionPathWiki
        ? `https://wiki.цож.рф/ru/${criterionPathWiki.replace(/\./g, '')}`
        : ''
    return(
        <Fragment>
            <Grid item xs={ 12 } className={ 'task-page__status' }>
                <Typography variant="body1">Статус: { checkListTaskMeta?.[TASK_STATUS_ATTR] }</Typography>
            </Grid>
            <Grid item xs={ 12 } className={ 'task-page__geo' }>
                { geoError ? <Typography>Геолокация отключена</Typography> :
                    <Button
                        href={ userAddress }
                        target="_blank"
                        rel="noopener"
                    >
                        Ваша локация на Яндекс.Картах
                    </Button>
                }
            </Grid>
            <Grid item xs={ 12 } className={ 'task-page__name' }>
                <Typography
                    sx={{ fontWeight:600 }}
                >
                    { checkListTaskMeta?.[TASK_CRITERION_NAME_ATTR] }
                </Typography>
            </Grid>
            <Grid item xs={ 12 } className={ 'task-page__meta' }>
                <Table>
                    <TableBody>
                        { TASK_META_PARAMS.map(param =>
                            <TableRow key={ param.name }>
                                <TableCell component="th" scope="row">
                                    { param.rus_name }
                                </TableCell>
                                <TableCell align="right">
                                    { param.name === TASK_CRITERION_PATH_ATTR
                                        ?
                                        <Button
                                            href={ criterionPathWikiUrl }
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            { getNestedValue(checkListTaskMeta, param.name) }
                                        </Button>
                                        || NO_DATA_STR
                                        : getNestedValue(checkListTaskMeta, param.name) || NO_DATA_STR
                                    }
                                </TableCell>
                            </TableRow>
                        ) }
                        <TableRow key={ 'ts' }>
                            <TableCell component="th" scope="row">
                                Время последнего замера
                            </TableCell>
                            <TableCell align="right">
                                { measureDateTime }
                            </TableCell>
                        </TableRow>
                        <TableRow key={ 'measureAddress' }>
                            <TableCell component="th" scope="row">
                                Локация последнего замера
                            </TableCell>
                            <TableCell align="right">
                                { long && lat ?
                                    <Button
                                        href={ measureAddress }
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        На Яндекс.Картах
                                    </Button>
                                    : NO_DATA_STR
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Fragment>
    )
}
export default TaskHeader
