import React from "react"
import {
    Routes,
    Route,
} from 'react-router-dom'
import {
    ROUTE_URL_AUTH,
    ROUTE_URL_403,
    ROUTE_URL_ANY,
    ROUTE_URL_CHECK_LIST,
    ROUTE_URL_SINGLE_TASK,
} from './constants'
import AuthPage from "../components/core/auth/AuthPage"
import HomePage from "../components/features/home/HomePage"
import Forbidden from "../components/core/service_pages/Forbidden"
import NotFound from "../components/core/service_pages/NotFound"
import PrivateLayout from "../components/common/private_layout/PrivateLayout"
import CheckList from "../components/features/check_list/CheckList"
import SingleTaskPage from "../components/features/single_task/SingleTaskPage"


const routes = (
    <Routes>
        <Route path={ ROUTE_URL_AUTH } element={ <AuthPage/> }/>
        <Route element={ <PrivateLayout/> }>
            <Route index element={ <HomePage/> }/>
            <Route path={ ROUTE_URL_CHECK_LIST } element={ <CheckList/> }/>
            <Route path={ ROUTE_URL_SINGLE_TASK } element={ <SingleTaskPage/> }/>
            <Route path={ ROUTE_URL_403 } element={ <Forbidden/> }/>
            <Route path={ ROUTE_URL_ANY } element={ <NotFound/> }/>
        </Route>
    </Routes>
)

export default routes
