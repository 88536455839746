import { ROUTE_URL_403, ROUTE_URL_AUTH } from "../routes/constants"
import browserHistory from "../browserHistory"

const HTTP_UNAUTHORIZED = 401
const HTTP_FORBIDDEN = 403


const checkStatus = async response => {
    if(response.ok) {
        return Promise.resolve(response)
    } else {
        if (response.status === HTTP_FORBIDDEN)
            browserHistory.push(ROUTE_URL_403)
        if (response.status === HTTP_UNAUTHORIZED)
            browserHistory.push(ROUTE_URL_AUTH, { from: window.location.pathname })

        let errorMessage
        try {
            const responseObj = await response.json()
            errorMessage = responseObj.detail
        } catch (e) {
            // - если не удалось распарсить json, мы дальше подставим значение
            // - почему подстановка не тут?
            // - потому что пустой error нас тоже не устраивает, даже если в ответе json
        }
        if(!errorMessage)
            errorMessage = `${ response.url } (${ response.status })`
        return Promise.reject(Error(errorMessage))
    }
}

const apiCall = (endpoint, { body, asResponse = false, ...restConfig } = {}) => {
    const url = process.env.REACT_APP_API_PATH + endpoint
    const headers = {
        'Content-Type': 'application/json',
    }
    const config = {
        method: body ? 'POST' : 'GET',
        ...restConfig,
        headers: {
            ...headers,
            ...restConfig.headers,
        },
        credentials: 'include',
    }
    if (body)
        config.body = body

    const fetchPromise = fetch(url, config).then(r => checkStatus(r))
    if (asResponse)
        return fetchPromise
    return fetchPromise.then(r => r.json())
}

export default apiCall
