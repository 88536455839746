import React from 'react'
import ReactDOM from 'react-dom/client'
import store from './store'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as Router } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material'
import App from './App'
import ErrorBoundary from "./components/core/service_pages/ErrorBoundary"
import routes from "./routes/routes"
import history from "./browserHistory"

const cozhTheme = createTheme(
    {
        palette:
        {
            primary:
            {
                main:'#0a8fcf',
            },
            secondary:
            {
                main: '#0e6e9e',
            },
        },
    })

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <ErrorBoundary>
        <Provider store={ store }>
            <Router history={ history }>
                <ThemeProvider theme={ cozhTheme }>
                    <App>{ routes }</App>
                </ThemeProvider>
            </Router>
        </Provider>
    </ErrorBoundary>
)
