import apiCall from "./api"


const authApi = { basePath: '/auth' }
authApi.getCredentials = () => apiCall(authApi.basePath + '/credentials')
authApi.login = (loginFormData) => apiCall(
    authApi.basePath + '/login',
    {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(loginFormData),
    }
)
authApi.logout = () => apiCall(authApi.basePath + '/logout', { method: 'POST', asResponse: true })
export default authApi
