import { Grid, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

const TaskImagePreview = ({
    image,
    onIconDel,
}) =>
    image  ?
        <Grid item xs={ 12 } className={ 'task-page__image-preview' }>
            <IconButton
                onClick={ onIconDel }
                className={ 'task-page__image-del-icon' }
            >
                <DeleteIcon/>
            </IconButton>
            <img
                alt={ 'preview' }
                src={ typeof image === 'string' ? image : `${image?.srcBase64 || ''}` }
            />
        </Grid> :
        null

export default TaskImagePreview
