import { createSelector, createSlice } from '@reduxjs/toolkit'
import tasksApi from "../../../api/tasksApi"
import { TASK_ID_ATTR } from "./constants"

const initialState = {
    tasks: [],
    fetchingTasks: false,
    tasksReceived: false,
    tasksError: '',
}

const checkListSlice = createSlice({
    name: 'checkList',
    initialState,
    reducers : {
        getTasksRequest: (state) => {
            state.fetchingTasks = true
            state.tasksReceived = initialState.tasksReceived
            state.tasksError = initialState.tasksError
        },
        getTasksSuccess: (state, action) => {
            state.tasks = action.payload.items
            state.fetchingTasks = false
            state.tasksReceived = true
        },
        getTasksFail: (state, action) => {
            state.fetchingTasks = false
            state.tasksReceived = false
            state.tasksError = action.payload
        },
    },
})

export const {
    getTasksRequest,
    getTasksSuccess,
    getTasksFail,
} = checkListSlice.actions

export const getTasks = () => dispatch => {
    dispatch(getTasksRequest())
    tasksApi.getTasks()
        .then(data => dispatch(getTasksSuccess(data)))
        .catch(err => dispatch(getTasksFail(err.message)))
}

const DEFAULT_CHECK_LIST_META = {}
export const getTaskMetaSelector = ( taskId, useSingleTaskMeta = false ) =>
    createSelector(
        [
            state => state.checkList.tasks,
            state => state.task.taskMeta,
        ],
        (checkListTasks, singleTaskMeta) => useSingleTaskMeta
            ? singleTaskMeta
            : checkListTasks.find( task => task?.[TASK_ID_ATTR] === taskId ) || DEFAULT_CHECK_LIST_META
    )

export default checkListSlice.reducer
