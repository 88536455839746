/*
    для того чтобы добавить новые массивы типов:
    https://www.iana.org/assignments/media-types/media-types.xhtml#image
*/
export const ACCEPTED_FILE_TYPES = {
    allImages: 'image/*',
}

export const BYTES_IN_KILOBYTE = 1024

export const UNITS = {
    BYTE: 'byte',
    KB: 'kb',
    MB: 'mb',
    GB: 'gb',
}

const ERROR_PARAMS_TEMPLATE = 'ошибка в значении одного из параметров'
export const ERROR_MESSAGES = {
    notAcceptableFormat: 'выбранный формат не поддерживается',
    fileSizeExceeded: 'превышение допустимого объёма загрузки файла',
    unacceptableFileType: 'недопустимый формат файла',
    invalidImageDimension: 'недопустимый размер изображения',
    errorFileSizeValidationParams: `${ ERROR_PARAMS_TEMPLATE } объёма файла`,
    errorAcceptTypesValidationParams: `${ ERROR_PARAMS_TEMPLATE } типа файла`,
    errorImageValidationParams: `${ ERROR_PARAMS_TEMPLATE } размера изображения`,

    errorFileValidationParams: 'ошибка значений параметров проверки файлов',
}
