import { Fragment, useEffect, useMemo, useState } from "react"
import {
    Grid,
    IconButton,
} from "@mui/material"
import SortIcon from "@mui/icons-material/Sort"
import { FILTER_ATTRS, TASK_HOUSE_ENTITY_DESCRIPTION_ATTR } from "../constants"
import { filterArrayByKey, getNestedValue } from "../../../../utils/utils"
import { VALUE_ALL } from "../../../../utils/constants"
import TaskFilterSelector from "./TaskFilterSelector"


const CheckListFilters = ({
    tasks,
    setFilteredTasks,
    setPage,
}) => {
    const [filtersMenuState, setFiltersMenuState] = useState(false)
    const [filters, setFilters] = useState({})

    const options = useMemo(
        () => {
            let optionsTmp = {}
            FILTER_ATTRS.forEach( attr => optionsTmp[attr.value] = [] )
            tasks.forEach(
                task => FILTER_ATTRS.forEach( attr => optionsTmp[attr.value].push(getNestedValue(task, attr.value)))
            )
            FILTER_ATTRS.forEach( attr => optionsTmp[attr.value] = [...new Set(optionsTmp[attr.value])].sort() )
            return optionsTmp
        }
        , [tasks]
    )

    const filteredTasks = useMemo(
        () => {
            let tasksTmp = [ ...tasks ]
            FILTER_ATTRS.forEach( attr => {
                if (!!filters[attr.value])
                    tasksTmp = filterArrayByKey(tasksTmp, attr.value, filters[attr.value])
            } )
            return tasksTmp
        },
        [filters, tasks]
    )

    const sortedTasks = useMemo(
        () => Array.isArray(filteredTasks)
            ? [...filteredTasks].sort(
                (prev, next) => {
                    let prevValue = getNestedValue(prev, TASK_HOUSE_ENTITY_DESCRIPTION_ATTR)
                    let nextValue = getNestedValue(next, TASK_HOUSE_ENTITY_DESCRIPTION_ATTR)
                    return prevValue < nextValue
                        ? -1
                        : prevValue > nextValue
                            ? 1
                            : 0
                })
            : [],
        [filteredTasks]
    )

    useEffect( () => setFilteredTasks(sortedTasks), [setFilteredTasks, sortedTasks])

    return (
        <Fragment>
            <Grid
                container
                direction={ "row" }
                justifyContent="space-around"
                alignItems="flex-end"
                sx={{ mt: 3 }}
            >
                <IconButton
                    size="small"
                    onClick={ () => setFiltersMenuState(!filtersMenuState) }
                >
                    <SortIcon />
                    Фильтры
                </IconButton>
            </Grid>
            { filtersMenuState &&
                <Grid
                    id="check_list_filters_box"
                    container
                    direction={ "row" }
                    justifyContent="space-around"
                    alignItems="flex-end"
                    sx={{ mt: 1 }}
                >
                    { FILTER_ATTRS.map( attr =>
                        <TaskFilterSelector
                            key={ attr.value }
                            options={ options[attr.value] }
                            onChange={ (e) => {
                                setFilters( prev => ({ ...prev, [attr.value]: e.target.value }))
                                setPage(1)
                            } }
                            label={ attr.label }
                            value={ filters[attr.value] || VALUE_ALL }

                        />
                    ) }
                </Grid>
            }
        </Fragment>
    )
}


export default CheckListFilters
