import { VALUE_ALL } from "./constants"

export const sortArrayByKey = (array = [], key) => {
    if (!Array.isArray(array))
        return []
    const arrayForSort = [...array]
    return arrayForSort.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
}

export const groupArrayByKey = (array = [], key) => {
    const arrayForGroup = [...array]
    return arrayForGroup.reduce((r, a) => {
        r[a[key]] = r[a[key]] || []
        r[a[key]].push(a)
        return r
    }, Object.create(null))
}

export const groupArrayByFunc = (array = [], getKeyFunc) => {
    const arrayForGroup = [...array]
    return arrayForGroup.reduce((r, a) => {
        const key = getKeyFunc( a )
        r[key] = r[key] || []
        r[key].push(a)
        return r
    }, Object.create(null))
}

export const isObject = o => o instanceof Object && o.constructor === Object

/**
 * Распаковать вложенное значение объекта
 * @param o - объект для распаковки
 * @param path - путь из ключей, разделенных через точку
 * @returns {null|*}
 */
export const getNestedValue = (o, path) => {
    if (!isObject(o))
        return null
    let steps = String(path).split('.')
    let value = { ...o }
    while (steps.length && value !== undefined)
        value = value?.[steps.shift()]
    return value
}

export const filterArrayByKey = (array = [], key, value, anyValue = VALUE_ALL) => {
    const arrayForFilter = [...array]
    if (!!anyValue && value === anyValue) return arrayForFilter
    return arrayForFilter.filter( el => getNestedValue(el, key) === value )
}

export const arrayRemoveByIndex = (arr, key) => {
    const tmpArr = [...arr]
    tmpArr.splice( key, 1 )
    return tmpArr
}
