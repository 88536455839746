import { BYTES_IN_KILOBYTE, UNITS } from "../constants"

/**
 * преобразовывает объём информации до количества байтов
 *
 * @param {number} value - количество объёма информации
 * @param {string} unit - разрядность
 * @return {number}
 */
export const toByte = (value, unit) => {
    if (!value || !unit) return 0

    const units = Object.values(UNITS)
    return value * (BYTES_IN_KILOBYTE ** units.indexOf(unit))
}

/**
 *
 * @param {File} file
 * @return {Promise<Object>}
 */
export const getFileImageDimensions = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
            const image = new Image()
            image.src = event.target.result
            image.onload = () => {
                const { width, height } = image
                resolve({ width, height })
                return { width, height }
            }
            image.onerror = err => reject(err)
        }
    })
}

