import { ERROR_MESSAGES, ACCEPTED_FILE_TYPES } from "../constants"
import { toByte, getFileImageDimensions } from "./utils"


/**
 * проверка превышения объёма файла доступного лимита
 * @param {File} file
 * @param {Object} maxFileSize
 * @param {number} maxFileSize.value
 * @param {string} maxFileSize.unit
 * @return {(string | undefined)}
 */
const validateFileSize = (file, { value, unit }) => {
    if (!file || !value || !unit) {
        return ERROR_MESSAGES.errorFileSizeValidationParams
    }

    return file.size > toByte(value, unit) ? ERROR_MESSAGES.fileSizeExceeded : undefined
}

/**
 * проверка соответствия файла, указанному списку типов
 * @param {File} file
 * @param {(Array<string> | string)} accept
 * @return {(string | undefined)}
 */
const validateAcceptTypes = (file, accept) => {
    if (!file || !accept || !accept.length) {
        return ERROR_MESSAGES.errorAcceptTypesValidationParams
    }

    if (accept === ACCEPTED_FILE_TYPES.allImages) {
        return file?.type?.substring(0, accept.indexOf('/')) !== accept.substring(0, accept.indexOf('/'))
            ? ERROR_MESSAGES.unacceptableFileType : undefined
    }

    return typeof accept === 'string'
        ? file?.type !== accept
            ? ERROR_MESSAGES.unacceptableFileType : undefined
        : accept.some(acceptType => file.type === acceptType)
            ? undefined : ERROR_MESSAGES.unacceptableFileType
}

/**
 *
 * @param {File} file
 * @param {Object} dimensionLimits
 * @param {number} dimensionLimits.minWidth
 * @param {number} dimensionLimits.minHeight
 * @param {number} dimensionLimits.maxWidth
 * @param {number} dimensionLimits.maxHeight
 * @return {Promise<(string|undefined)>}
 */
const validateImageDimension = async (file, { minWidth, minHeight, maxWidth, maxHeight }) => {
    const { width, height } = await getFileImageDimensions(file)

    if (
        (minWidth && width < minWidth) ||
        (minHeight && height < minHeight) ||
        (maxWidth && width > maxWidth) ||
        (maxHeight && height > maxHeight)
    ) {
        return ERROR_MESSAGES.invalidImageDimension
    }

    return undefined
}

/**
 *
 * @param {File} file
 * @param {Object} validationRules
 * @return {Promise<(string|undefined)>}
 */
export const validateUploadingImage = async (file, validationRules) => {
    const { accept, maxFileSize, imgDimensionLimits } = validationRules

    return (
        (accept && validateAcceptTypes(file, accept)) ||
        (maxFileSize && validateFileSize(file, maxFileSize)) ||
        (imgDimensionLimits && await validateImageDimension(file, imgDimensionLimits))
    )
}
