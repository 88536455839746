import { Grid, Typography } from "@mui/material"
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred"

const CheckListStatus = ({
    tasksCount,
    taskStatusDoneCnt,
}) =>
    <Grid
        container
        direction = "row"
        textAlign = "center"
        alignItems = "center"
        justifyContent = "space-around"
    >
        <Grid item>
            <CrisisAlertIcon fontSize="large"/>
            <Typography variant='h5'>{ tasksCount }</Typography>
        </Grid>
        <Grid item>
            <TaskAltIcon fontSize="large"/>
            <Typography variant='h5'>{ taskStatusDoneCnt }</Typography>
        </Grid>
        <Grid item>
            <ReportGmailerrorredIcon fontSize="large"/>
            <Typography variant='h5'>
                { tasksCount - taskStatusDoneCnt }
            </Typography>
        </Grid>
    </Grid>

export default CheckListStatus
