import {
    TASK_CRITERION_CONTROL_LOCATION_ATTR,
    TASK_CRITERION_CONTROL_OBJECT_ATTR,
    TASK_CRITERION_TOOLS_ATTR,
    TASK_CRITERION_TYPES_ATTR,
    TASK_CRITERION_ATTENDANT_ATTR,
    TASK_HOUSE_ENTITY_DESCRIPTION_ATTR,
    TASK_CRITERION_PATH_ATTR,
} from "../../features/check_list/constants"

export const MIN_COMMENT_LENGTH = 150
export const TASK_META_PARAMS = [
    { name: TASK_CRITERION_TYPES_ATTR, rus_name: 'Тип' },
    { name: TASK_CRITERION_CONTROL_OBJECT_ATTR, rus_name: 'Что проверить' },
    { name: TASK_CRITERION_CONTROL_LOCATION_ATTR, rus_name: 'Где проверить' },
    { name: TASK_CRITERION_ATTENDANT_ATTR, rus_name: 'Требуется представитель УК' },
    { name: TASK_CRITERION_TOOLS_ATTR, rus_name: 'Инструмент' },
    { name: TASK_HOUSE_ENTITY_DESCRIPTION_ATTR, rus_name: 'Место проверки' },
    { name: TASK_CRITERION_PATH_ATTR, rus_name: 'Индекс критерия' },
]
export const NEED_HELP_BUTTON_LABEL = 'Нужна помощь!'
