import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import Task from "../../common/task/Task"
import { getTaskMeta } from "../../common/task/taskSlice"


const SingleTaskPage = () => {
    const dispatch = useDispatch()
    const { taskId } = useParams()

    const fetchingTaskMeta = useSelector( state => state.task.fetchingTaskMeta )
    const taskMetaReceived = useSelector( state => state.task.taskMetaReceived )
    const taskMetaError = useSelector( state => state.task.taskMetaError )

    useEffect( () => {
        if ( taskMetaReceived ) return
        dispatch(getTaskMeta(taskId))
    }, [ dispatch, taskId, taskMetaReceived ] )

    return (
        fetchingTaskMeta ?
            <CircularProgress/> :
            taskMetaError ?
                taskMetaError :
                <Task taskId={ taskId } useSingleTaskMeta />
    )
}

export default SingleTaskPage
