import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { DEFAULT_FILTER_OPTION } from "../../../../utils/constants"

const TaskFilterSelector = ({ options, onChange, label, value }) =>
    <Grid item xs={ 12 } sm={ 6 } justifyContent="space-around" display='flex'>
        <FormControl size="small" fullWidth  sx={{ mt: 2, maxWidth: 280 }} >
            <InputLabel id="filter_task_label">{ label }</InputLabel>
            <Select
                value={ value }
                label={ label }
                onChange={ onChange }
            >
                <MenuItem
                    key={ DEFAULT_FILTER_OPTION.value }
                    value={ DEFAULT_FILTER_OPTION.value }
                >
                    { DEFAULT_FILTER_OPTION.label }
                </MenuItem>
                { options.map( option => <MenuItem key={ option } value={ option }>{ option }</MenuItem> ) }
            </Select>
        </FormControl>
    </Grid>

export default TaskFilterSelector
