import { Navigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useRef } from "react"
import { login } from "./authSlice"
import { ROUTE_URL_HOME } from "../../../routes/constants"
import { Grid, Typography, TextField, Button, Box, Paper } from "@mui/material"

const AuthPage = () => {
    const dispatch = useDispatch()
    const _loginFormRef = useRef()

    const authenticatedUserid = useSelector( state => state.auth.authenticatedUserid )
    const fetchingLogin = useSelector( state => state.auth.fetchingLogin )
    const loginError = useSelector( state => state.auth.loginError )

    const location = useLocation()
    const previous_location = location.state?.from
    const redirect_to = (previous_location && previous_location !== '/auth') ? previous_location : ROUTE_URL_HOME
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            { authenticatedUserid ? <Navigate to={ redirect_to }/> : null }
            <Grid item>
                <Box
                    component="img"
                    src="logoCozh2.png"
                    sx={{
                        width:150,
                    }}
                />
            </Grid>
            <Paper
                sx={{
                    mt: 7,
                    width: 300,
                    height: 420,
                    borderRadius: 4,
                }}
                elevation={ 3 }
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                
                    <form ref={ _loginFormRef }>
                        <Grid item>
                            <Typography variant="h4" align="center" sx={{ mt: 4, fontWeight: 600 }}>
                                Вход
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Логин"
                                variant="outlined"
                                type="text"
                                name="username"
                                sx={{ width:270, height: 50, mt: 5 }}
                                required
                                autoComplete="username"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Пароль"
                                variant="outlined"
                                type="password" 
                                name="password"
                                sx={{ width:270, height: 50, mt:5 }}
                                required
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                sx={{ width:270, height: 50, mt:5 }}
                                variant="contained"
                                disabled={ fetchingLogin }
                                onClick={ (e) => {
                                    e.preventDefault() // Firefox упорно принимал за submit и перегружал страницу
                                    dispatch(
                                        login(Object.fromEntries(new FormData(_loginFormRef.current).entries()))
                                    )     
                                } }
                            >{ fetchingLogin ? 'Вход выполняется...' : 'Войти' }
                            </Button>  
                        </Grid>
                    </form>
                    {
                        loginError &&
                        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                            Во время авторизации произошла ошибка
                        </Typography>
                    }
                </Grid>
            </Paper>
        </Grid>
    )
}

export default AuthPage
