import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Grid,
    Typography,
    CircularProgress,
    Dialog,
    Box,
    Pagination,
} from "@mui/material"
import { getTasks } from "./checkListSlice"
import {
    DATE_FORMAT,
    TASK_ID_ATTR,
    TASK_STATUS_ATTR,
    TASK_STATUS_COMPLETE,
    TASKS_PER_PAGE,
} from "./constants"
import { endOfWeek, format, startOfWeek } from "date-fns"
import Task from "../../common/task/Task"
import Header from "../../common/header/Header"
import TaskTile from './check_list_elements/TaskTile'
import { ArrowBackSharp } from "@mui/icons-material"
import CheckListStatus from "./check_list_elements/CheckListStatus"
import CheckListFilters from "./check_list_elements/CheckListFilters"


const CheckList = () => {
    const dispatch = useDispatch()
    const currWeekStart = format(startOfWeek(new Date(), { weekStartsOn: 2 }), DATE_FORMAT)
    const currWeekEnd = format(endOfWeek(new Date(), { weekStartsOn: 2 }), DATE_FORMAT)

    const [page, setPage] = useState(1)

    const [selectedTaskId, setSelectedTaskId] = useState()
    const [filteredTasks, setFilteredTasks] = useState([])

    const tasks = useSelector( state => state.checkList.tasks )
    const fetchingTasks = useSelector( state => state.checkList.fetchingTasks )
    const tasksReceived = useSelector( state => state.checkList.tasksReceived )
    const tasksError = useSelector( state => state.checkList.tasksError )
    
    useEffect( () => {
        if ( tasksReceived ) return
        dispatch(getTasks())
    }, [dispatch, tasksReceived] )

    const pagesCount = Math.ceil((filteredTasks?.length || 0) / TASKS_PER_PAGE)
    const tasksCount = tasks?.length || 0
    const taskStatusDoneCnt = useMemo(
        () => tasks?.filter(
            task => task?.[TASK_STATUS_ATTR] === TASK_STATUS_COMPLETE
        ).length || 0,
        [tasks]
    )

    return (
        /* Здесь tasksCount для того, чтобы при первом открытии была загрузка, но не было мельканий после отправки
         * замеров и повторного запроса тасков.
         */
        !tasksCount && fetchingTasks
            ? <CircularProgress/>
            : tasksError
                ? tasksError
                :
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={ 'check-list-page' }
                >
                    <Grid textAlign="center" item sx={{ mb: 3 }}>
                        <Typography variant='h6'>
                            Ваш чек-лист на { currWeekStart } - { currWeekEnd } до 23:59
                        </Typography>
                    </Grid>
                    <CheckListStatus
                        tasksCount={ tasksCount }
                        taskStatusDoneCnt={ taskStatusDoneCnt }
                    />
                    <CheckListFilters
                        tasks={ tasks }
                        setFilteredTasks={ setFilteredTasks }
                        setPage={ setPage }
                    />
                    <Grid
                        container
                        sx={{ mt: 1 }}
                    >
                        { filteredTasks
                            .slice((page - 1) * TASKS_PER_PAGE, page * TASKS_PER_PAGE)
                            .map(task =>
                                <TaskTile
                                    key={ task?.[TASK_ID_ATTR] }
                                    taskMeta={ task }
                                    onTaskSelect={ setSelectedTaskId }
                                />
                            )
                        }
                    </Grid>
                    <Pagination
                        count={ pagesCount }
                        page={ page }
                        onChange={ (_, p) => setPage(p) }
                        color="primary"
                        size="medium"
                        siblingCount={ 1 }
                        boundaryCount={ 1 }
                    />
                    <Dialog
                        fullScreen
                        open={ !!selectedTaskId  }
                        onClose={ () => setSelectedTaskId(undefined) }
                    >
                        <Header
                            onIconClick={ () => setSelectedTaskId(undefined) }
                            icon={ <ArrowBackSharp/> }
                        />
                        <Box sx={{ m:2, mt: 10 }}>
                            <Task taskId={ selectedTaskId }/>
                        </Box>
                    </Dialog>
                </Grid>
    )
}

export default CheckList
