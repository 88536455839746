import { memo } from "react"
import PropTypes from "prop-types"
import { Button, Grid, Typography, Card, CardContent, CardActions, Divider } from "@mui/material"
import { getNestedValue } from "../../../../utils/utils"
import {
    TASK_CRITERION_CONTROL_LOCATION_ATTR,
    TASK_CRITERION_CONTROL_OBJECT_ATTR,
    TASK_HOUSE_ENTITY_DESCRIPTION_ATTR,
    TASK_HOUSE_ENTITY_HOUSE_ATTR,
    TASK_ID_ATTR,
    TASK_STATUS_ATTR,
} from "../constants"


const TaskTile = memo(({
    taskMeta,
    onTaskSelect,
}) => {
    const { address } = getNestedValue(taskMeta, TASK_HOUSE_ENTITY_HOUSE_ATTR) || {}
    return (
        <Grid item xs={ 12 } sx={{ mb: 3 }}>
            <Card sx={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="h6">{ address }</Typography>
                    <Divider/>
                    <Typography variant="body2">
                        { getNestedValue(taskMeta, TASK_CRITERION_CONTROL_OBJECT_ATTR) }
                    </Typography>
                    <Divider/>
                    <Typography variant="body2">
                        { getNestedValue(taskMeta, TASK_CRITERION_CONTROL_LOCATION_ATTR) }
                    </Typography>
                    <Divider/>
                    <Typography variant="body2">
                        { getNestedValue(taskMeta, TASK_HOUSE_ENTITY_DESCRIPTION_ATTR) }
                    </Typography>
                    <Divider/>
                    <Typography variant="h6" align='right' >
                        { getNestedValue(taskMeta, TASK_STATUS_ATTR) }
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={ () => onTaskSelect(getNestedValue(taskMeta, TASK_ID_ATTR)) }
                    >
                        Перейти к осмотру
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
})

TaskTile.propTypes = {
    taskMeta: PropTypes.object.isRequired,
    onTaskSelect: PropTypes.func.isRequired,
}
export default TaskTile
