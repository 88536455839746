import React from "react"
import { useSelector } from "react-redux"
import { Grid } from "@mui/material"
import Header from "./components/common/header/Header"
import { ROUTE_URL_HOME } from "./routes/constants"
import { useNavigate } from "react-router-dom"

const App = props => {
    const authenticatedUserid = useSelector( state => state.auth.authenticatedUserid )
    const navigate = useNavigate()

    return(
        <div className="app">
            { authenticatedUserid &&
                <Header
                    onBackButtonClick={ () => navigate(ROUTE_URL_HOME) }
                />
            }
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 10 }}
            >
                { props.children }
            </Grid>
        </div>
    )
}

export default App
