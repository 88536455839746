import { Fragment } from "react"
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material"
import { NEED_HELP_BUTTON_LABEL } from "../constants"

const TaskResponseSelect = ({
    response,
    responseOptions,
    setResponse,
    needHelp,
    setNeedHelp,
}) =>
    <Fragment>
        <Grid item xs={ 6 } className={ 'task-page__response' }>
            { Array.isArray(responseOptions) ?
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Ответ</InputLabel>
                    <Select
                        fullWidth
                        name="response"
                        value={ response }
                        label="Ответ"
                        onChange={ e => setResponse(e.target.value) }
                        disabled={ needHelp }
                    >
                        { responseOptions.map( opt =>
                            <MenuItem key={ opt } value={ opt }>{ opt }</MenuItem>
                        ) }
                    </Select>
                </FormControl> :
                <Typography>Нет вариантов ответа.</Typography>
            }
        </Grid>
        <Grid item xs={ 5 } className={ 'task-page__help' } sx={{ ml:2 }}>
            <FormControlLabel
                control={ <Switch checked={ needHelp } onClick={ () => setNeedHelp( !needHelp ) }/> }
                label={ NEED_HELP_BUTTON_LABEL }
            />
        </Grid>
    </Fragment>


export default TaskResponseSelect
