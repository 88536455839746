import apiCall from "./api"


const tasksApi = { basePath: '/tasks/' }

tasksApi.getTasks = () => apiCall(tasksApi.basePath)

tasksApi.getTaskMeta = (taskId ) =>
    apiCall(tasksApi.basePath + `${taskId}`)

tasksApi.getTaskMeasures = (taskId ) =>
    apiCall(tasksApi.basePath + `${taskId}/measures`)

tasksApi.sendTaskMeasures = (taskId, measureFormData ) => apiCall(
    tasksApi.basePath + `${taskId}/measures`,
    { body: JSON.stringify(measureFormData) }
)

export default tasksApi
