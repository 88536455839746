import React, { useEffect } from "react"
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import { checkUserCredentials } from "../../core/auth/authSlice"
import './private_layout.css'

const PrivateLayout = () => {
    const dispatch = useDispatch()
    const authenticatedUserid = useSelector( state => state.auth.authenticatedUserid )
    useEffect( () => dispatch(checkUserCredentials()), [dispatch])
    return(
        <div className={ 'private-layout' }>
            { authenticatedUserid ?
                <Outlet/> :
                <CircularProgress/>
            }
        </div>
    )
}

export default PrivateLayout
