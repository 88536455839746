import React from 'react'
import PropTypes from "prop-types"
import './file_uploader_loader.css'


// Компонент статуса загрузки картинок. Позаимствован из wifi-frontend
const FileUploaderLoader = ({ loadingText = 'Загрузка файла' }) => {
    return (
        <div className="file-uploader-loader">
            <div className="file-uploader-loader__text">
                { loadingText }
            </div>
            <div className="file-uploader-loader__bar">
                <i className="file-uploader-loader__thumb"/>
            </div>
        </div>
    )
}

FileUploaderLoader.propTypes = {
    loadingText: PropTypes.string,
}

export default FileUploaderLoader
