import { logout } from "../../core/auth/authSlice"
import { useDispatch } from "react-redux"
import { Button, Grid } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { ROUTE_URL_CHECK_LIST } from "../../../routes/constants"

document.title = 'ЦОЖ'


const HomePage = () => {
    const dispatch = useDispatch()
    return (
        <Grid
            className={ 'home-page' }
        >        
            <Grid item xs={ 12 }>
                <Button
                    sx={{ height: 50, mb: 3 }}
                    variant="contained"
                    component={ RouterLink }
                    to={ ROUTE_URL_CHECK_LIST }
                    fullWidth
                >
                    Мой чек-лист
                </Button>
            </Grid>
            <Grid item xs={ 12 }>
                <Button
                    sx={{ height: 50, mb:3 }}
                    variant="contained"
                    fullWidth
                >
                    Справочные материалы
                </Button>
            </Grid>
            <Grid item xs={ 12 }>
                <Button
                    sx={{ height: 50, mb:3 }}
                    variant="contained"
                    fullWidth
                >
                    Вопросы и ответы
                </Button>
            </Grid>
            <Grid item xs={ 12 }>
                <Button
                    sx={{ height: 50, mt:10, background:'#467da0' }}
                    variant="contained"
                    onClick={ () => dispatch(logout()) }
                    fullWidth
                >
                    { 'Выйти' }
                </Button>
            </Grid>
        </Grid>
    )
}
export default HomePage
