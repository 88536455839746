import { createSlice } from '@reduxjs/toolkit'
import tasksApi from "../../../api/tasksApi"
import { getTasks } from "../../features/check_list/checkListSlice"

const initialState = {
    taskMeta: {},
    fetchingTaskMeta: false,
    taskMetaReceived: false,
    taskMetaError: '',

    taskMeasures: [],
    fetchingTaskMeasures: false,
    taskMeasuresReceived: false,
    taskMeasuresError: '',

    sendingTaskMeasures: false,
    taskMeasuresSent: false,
    taskMeasuresSendError: '',
}

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers : {
        getTaskMetaRequest: (state) => {
            state.fetchingTaskMeta = true
            state.taskMetaReceived = initialState.taskMetaReceived
            state.taskMetaError = initialState.taskMetaError
        },
        getTaskMetaSuccess: (state, action) => {
            state.taskMeta = action.payload
            state.fetchingTaskMeta = false
            state.taskMetaReceived = true
        },
        getTaskMetaFail: (state, action) => {
            state.fetchingTaskMeta = false
            state.taskMetaReceived = false
            state.taskMetaError = action.payload
        },

        getTaskMeasuresRequest: (state) => {
            state.fetchingTaskMeasures = true
            state.taskMeasuresReceived = initialState.taskMeasuresReceived
            state.taskMeasuresError = initialState.taskMeasuresError
        },
        getTaskMeasuresSuccess: (state, action) => {
            state.taskMeasures = action.payload.items
            state.fetchingTaskMeasures = false
            state.taskMeasuresReceived = true
        },
        getTaskMeasuresFail: (state, action) => {
            state.fetchingTaskMeasures = false
            state.taskMeasuresReceived = false
            state.taskMeasuresError = action.payload
        },

        sendTaskMeasuresRequest: (state) => {
            state.sendingTaskMeasures = true
            state.taskMeasuresSent = initialState.taskMeasuresSent
            state.taskMeasuresSendError = initialState.taskMeasuresSendError
        },
        sendTaskMeasuresSuccess: (state) => {
            state.sendingTaskMeasures = false
            state.taskMeasuresSent = true
            state.taskMeasuresSendError = ''
        },
        sendTaskMeasuresFail: (state, action) => {
            state.sendingTaskMeasures = false
            state.taskMeasuresSent = false
            state.taskMeasuresSendError = action.payload
        },
    },
})

export const {
    getTaskMetaRequest,
    getTaskMetaSuccess,
    getTaskMetaFail,

    getTaskMeasuresRequest,
    getTaskMeasuresSuccess,
    getTaskMeasuresFail,

    sendTaskMeasuresRequest,
    sendTaskMeasuresSuccess,
    sendTaskMeasuresFail,
} = taskSlice.actions

export const getTaskMeta = (taskId) => dispatch => {
    dispatch(getTaskMetaRequest())
    tasksApi.getTaskMeta(taskId)
        .then(data => dispatch(getTaskMetaSuccess(data)))
        .catch(err => dispatch(getTaskMetaFail(err.message)))
}

export const getTaskMeasures = (taskId) => dispatch => {
    dispatch(getTaskMeasuresRequest())
    tasksApi.getTaskMeasures(taskId)
        .then(data => dispatch(getTaskMeasuresSuccess(data)))
        .catch(err => dispatch(getTaskMeasuresFail(err.message)))
}

export const sendTaskMeasures = (taskId, loginFormData, useSingleTaskMeta) => dispatch => {
    dispatch(sendTaskMeasuresRequest())
    tasksApi.sendTaskMeasures(taskId, loginFormData)
        .then(() => dispatch(sendTaskMeasuresSuccess()))
        .then(() => dispatch( !useSingleTaskMeta ? getTasks() : getTaskMeta(taskId)))
        .then(() => dispatch(getTaskMeasures(taskId)))
        .catch(err => dispatch(sendTaskMeasuresFail(err.message)))
}

export default taskSlice.reducer
