import { Alert, Snackbar } from "@mui/material"
function errorAlert({
    openErrorAlert,
    handleCloseErrorAlert = Function.prototype,
    alertText,
}) {
    return(
        <Snackbar
            open={ openErrorAlert }
            onClose={ handleCloseErrorAlert }
            autoHideDuration={ 3000 }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{ mb:14 }}
            
        >
            <Alert
                severity="error"
            >
                { alertText }
            </Alert>
        </Snackbar>
    )  
}
export default errorAlert
