import React from "react"
import PropTypes from "prop-types"


class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
    }

    render() {
        return (
            !!this.state.error ?
                JSON.stringify(
                    {
                        url: window.location.href,
                        referrer: document.referrer,
                        ua: window.navigator.userAgent,
                        resolution: `${ window.screen.width }x${ window.screen.height }`,
                        error: this.state.error?.message,
                        stacktrace: this.state.errorInfo?.componentStack,
                    },
                    null,
                    // eslint-disable-next-line no-magic-numbers
                    2
                )
                :
                this.props.children
        )
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
}
export default ErrorBoundary
