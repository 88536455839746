import { createSlice } from '@reduxjs/toolkit'
import authApi from "../../../api/authApi"
import browserHistory from "../../../browserHistory"
import { ROUTE_URL_AUTH } from "../../../routes/constants"

const initialState = {
    authenticatedUserid:'',
    authenticatedUserName:'',
    userScopes:[],
    fetchingUserCredentials: false,
    fetchingLogin: false,
    checkUserCredentialsError: '',
    loginError: '',
    logoutError: '',
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers : {
        checkUserCredentialsRequest: (state, action) => {
            state.fetchingUserCredentials = true
        },
        checkUserCredentialsSuccess: (state, action) => {
            state.fetchingUserCredentials = false
            state.userScopes = action.payload.scopes
            state.authenticatedUserid = action.payload.id
            state.authenticatedUserName = action.payload.name
            state.checkUserCredentialsError = ''
        },
        checkUserCredentialsFail: (state, action) => {
            state.fetchingUserCredentials = false
            state.checkUserCredentialsError = action.payload
        },

        loginRequest: (state, action) => {
            state.fetchingLogin = true
        },
        loginSuccess: (state, action) => {
            state.userScopes = action.payload.scopes
            state.authenticatedUserid = action.payload.id
            state.authenticatedUserName = action.payload.name
            state.fetchingLogin = false
            state.loginError = ''
        },
        loginFail: (state, action) => {
            state.loginError = action.payload
            state.fetchingLogin = false
        },

        logoutSuccess: (state, action) => {
            state.authenticatedUserid = initialState.authenticatedUserid
            state.userScopes = initialState.userScopes
        },
        logoutFail: (state, action) => {
            state.logoutError = action.payload
        },
    },
})

export const {
    checkUserCredentialsRequest,
    checkUserCredentialsSuccess,
    checkUserCredentialsFail,
    
    loginRequest,
    loginSuccess,
    loginFail,

    logoutSuccess,
    logoutFail,
} = authSlice.actions

export const checkUserCredentials = () => dispatch => {
    dispatch(checkUserCredentialsRequest())
    authApi.getCredentials()
        .then(data => dispatch(checkUserCredentialsSuccess(data)))
        .catch(err => dispatch(checkUserCredentialsFail(err.message)))
}

export const login = (loginFormData) => dispatch => {
    dispatch(loginRequest())
    authApi.login(loginFormData)
        .then(data => dispatch(loginSuccess(data)) )
        .catch(err => dispatch(loginFail(err.message)))
}

export const logout = () => dispatch => {
    authApi.logout()
        .then(() => dispatch(logoutSuccess()))
        .then(() => browserHistory.push(ROUTE_URL_AUTH))
        .catch(err => dispatch(logoutFail(err.message)))
}

export default authSlice.reducer
