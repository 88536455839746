import { useEffect, useRef, useState } from "react"
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
} from "@mui/material"
import { useGeoPosition } from "../../../hooks/useGeoLocation"
import ImageUploader from "../image_uploader/ImageUploader"
import "./task.css"
import { MIN_COMMENT_LENGTH } from "./constants"
import { JS_TIMESTAMP_MULTIPLIER, NO_DATA_STR } from "../../../utils/constants"
import { useDispatch, useSelector } from "react-redux"
import { getTaskMeasures, sendTaskMeasures } from "./taskSlice"
import { arrayRemoveByIndex, sortArrayByKey } from "../../../utils/utils"
import { getTaskMetaSelector } from "../../features/check_list/checkListSlice"
import ErrorAlert from "../../ui/error_alert/ErrorAlert"
import {
    TASK_RESPONSE_OPTIONS_ATTR,
    TASK_STATUS_ATTR,
    TASK_STATUS_NEED_HELP,
} from "../../features/check_list/constants"
import TaskResponseSelect from "./task_elements/TaskResponseSelect"
import TaskImagePreview from "./task_elements/TaskImagePreview"
import TaskHeader from "./task_elements/TaskHeader"


const Task = ({
    taskId,
    useSingleTaskMeta,
}) => {
    const dispatch = useDispatch()
    const imageUploaderInputRef = useRef()
    const [ comment, setComment ] = useState('')
    const [ imagesUrls, setImagesUrls ] = useState( [] )
    const [ imagesSrcs, setImagesSrcs ] = useState( [] )
    const [ response, setResponse ] = useState('')
    const [ needHelp, setNeedHelp ] = useState(false)
    const [ isOpenErrorAlert, setIsOpenErrorAlert ] = useState(false)
    const { latitude: curLat, longitude: curLon, error: geoError = '' } = useGeoPosition()

    const taskMeasures = useSelector( state => state.task.taskMeasures )
    const fetchingTaskMeasures = useSelector( state => state.task.fetchingTaskMeasures )
    const taskMeasuresReceived = useSelector( state => state.task.taskMeasuresReceived )
    const taskMeasuresError = useSelector( state => state.task.taskMeasuresError )
    const sendingTaskMeasures = useSelector( state => state.task.sendingTaskMeasures )
    const taskMeasuresSendError = useSelector( state => state.task.taskMeasuresSendError )

    const sortedMeasurements = sortArrayByKey( taskMeasures, 'ts' )
    const lastMeasureIndex = sortedMeasurements.length - 1
    const {
        ts,
        value = '',
        comment: measureComment = '',
        photo_url = '',
        images_urls,
        lat,
        long,
    } = sortedMeasurements[lastMeasureIndex] || {}
    const measureDateTime = ts ?
        new Date(ts * JS_TIMESTAMP_MULTIPLIER).toLocaleString("ru-RU") :
        NO_DATA_STR

    const checkListTaskMetaSelector = getTaskMetaSelector( taskId, useSingleTaskMeta )
    const checkListTaskMeta = useSelector( state => checkListTaskMetaSelector(state) )
    const taskStatus = checkListTaskMeta?.[TASK_STATUS_ATTR]
    const responseOptions = checkListTaskMeta?.[TASK_RESPONSE_OPTIONS_ATTR]
    const alertErrorText = ( geoError ? "Нет доступа к геолокации!" : "" ) +
        ( taskMeasuresSendError && `Ошибка API: ${ taskMeasuresSendError }` )

    useEffect( () => {
        if ( !taskId ) return
        dispatch(getTaskMeasures(taskId))
    }, [ dispatch, taskId ] )

    // Если этого не сделать, то даже после загрузки измерения будет пусто
    useEffect( () => {
        if ( !taskMeasuresReceived ) return
        setComment( measureComment )
        setResponse( value )
        setNeedHelp( taskStatus === TASK_STATUS_NEED_HELP )
        setImagesUrls( images_urls || [] )
        setImagesSrcs( [] )
    }, [ measureComment, photo_url, value, taskMeasuresReceived, taskStatus, images_urls ] )

    useEffect( () => {
        if ( !taskMeasuresSendError ) return
        setIsOpenErrorAlert(true)
    }, [ taskMeasuresSendError ] )

    const handleImageChange = (file) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            const srcBase64 = reader.result
            setImagesSrcs( arr => [...arr, srcBase64] )
            imageUploaderInputRef.current.value = ''
        }
        reader.readAsDataURL(file)
    }

    /* Не получилось сходу совладать с формами MUI и аплоадером картинок, поэтому так */
    const handleSubmitButtonChange = () => {
        if ( !taskId ) return
        const measureData = {
            task_id: taskId,
            value: !needHelp ? response || '' : '',
            lat: curLat || '',
            long: curLon || '',
            comment: comment || '',
            need_help: needHelp,
            images_src: imagesSrcs || [],
            images_urls: imagesUrls || [],
        }
        dispatch(sendTaskMeasures(taskId, measureData, useSingleTaskMeta))
    }

    const checkButtonEnabled = () => {
        const hasImages = (Array.isArray(imagesUrls) && imagesUrls.length > 0) ||
            (Array.isArray(imagesSrcs) && imagesSrcs.length > 0)
        return (
            ( response || needHelp ) &&
            ( hasImages || comment.length >= MIN_COMMENT_LENGTH ) &&
            !sendingTaskMeasures &&
            !fetchingTaskMeasures
        )
    }

    return (
        /* Здесь ts для того, чтобы при первом открытии замеров была загрузка, но не было мельканий после отправки
         * и повторного запроса замеров.
         */
        !ts && fetchingTaskMeasures ?
            <CircularProgress/> :
            taskMeasuresError ?
                taskMeasuresError :
                <Grid
                    container
                    className={ 'check-list-task-page' }
                    rowSpacing={ 2 }
                    sx={{ maxWidth:600, m:"0 auto" }}
                >
                    <ErrorAlert
                        openErrorAlert={ isOpenErrorAlert }
                        handleCloseErrorAlert = { () => setIsOpenErrorAlert(false) }
                        alertText = { alertErrorText }
                    />
                    <TaskHeader
                        long={ long }
                        lat={ lat }
                        curLon={ curLon }
                        curLat={ curLat }
                        geoError={ geoError }
                        checkListTaskMeta={ checkListTaskMeta }
                        measureDateTime={ measureDateTime }
                    />
                    <TaskResponseSelect
                        response={ response }
                        responseOptions={ responseOptions }
                        setResponse={ setResponse }
                        needHelp={ needHelp }
                        setNeedHelp={ setNeedHelp }
                    />
                    { Array.isArray(imagesUrls) && imagesUrls.map(( imageUrl, key ) =>
                        <TaskImagePreview
                            key={ `imageUrl${key}` }
                            image={ imageUrl }
                            onIconDel={ () => setImagesUrls( arr => arrayRemoveByIndex(arr, key)) }
                        />
                    ) }
                    { Array.isArray(imagesSrcs) && imagesSrcs.map(( imageSrc, key ) =>
                        <TaskImagePreview
                            key={ `imageSrc${key}` }
                            image={ imageSrc }
                            onIconDel={ () => setImagesSrcs( arr => arrayRemoveByIndex(arr, key)) }
                        />
                    ) }
                    <Grid item xs={ 12 } className={ 'task-page__image-btn' }>
                        <ImageUploader
                            ref={ imageUploaderInputRef }
                            onFilesChange={ handleImageChange }
                        />
                    </Grid>
                    <Grid item xs={ 12 } className={ 'task-page__comment' }>
                        <TextField
                            label="Комментарий"
                            variant="outlined"
                            multiline
                            fullWidth
                            onChange={ e => setComment(e.target.value) }
                            value={ comment }
                        />
                    </Grid>
                    <Grid item xs={ 12 } className={ 'task-page__submit' }>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={ !checkButtonEnabled() }
                            onClick={ () =>
                                geoError
                                    ? setIsOpenErrorAlert(true)
                                    : handleSubmitButtonChange()
                            }
                        >
                            Отправить
                        </Button>
                    </Grid>
                </Grid>
    )
}

export default Task
