import React, { useState, Fragment, forwardRef } from 'react'
import PropTypes from "prop-types"
import { Button } from "@mui/material"
import classNames from 'classnames'
import { ACCEPTED_FILE_TYPES, UNITS } from "./constants"
import { validateUploadingImage } from "./utils/validateUploading"
import FileUploaderLoader from "./loader/FileUploaderLoader"
import './image_uploader.css'


// Компонент загрузки картинок. Позаимствован из wifi-frontend
const ImageUploader = forwardRef((props, inputFileRef) => {
    const {
        accept = ACCEPTED_FILE_TYPES.allImages,
        onFilesChange,
    } = props

    const [inDropZone, setInDropZone] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState()

    const cancelEvent = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleImageChange = (file, errorMessage) => {
        if (errorMessage) {
            setErrorMessage(errorMessage)
        } else {
            setErrorMessage(undefined)
            onFilesChange(file)
        }
    }

    const handleDrop = async (e) => {
        cancelEvent(e)
        setInDropZone(false)
        setLoading(true)
        const files = [...e.dataTransfer.files]

        files.forEach(async function (el, index) {
            handleImageChange(el, await validateUploadingImage(el, props))
        })
        setLoading(false)
    }

    const handleDragOver = (e) => {
        cancelEvent(e)
        setInDropZone(true)
        setErrorMessage(undefined)
    }

    const handleFilesChange = async (e) => {
        setLoading(true)
        const files = [...e.target.files]

        files.forEach(async function (el, index) {
            handleImageChange(el, await validateUploadingImage(el, props))
        })

        setLoading(false)
    }

    const handleDragLeave = (e) => {
        cancelEvent(e)
        setInDropZone(false)
    }

    const handleDropSectionClick = (e) => {
        e.stopPropagation()
        inputFileRef.current?.click()
    }

    const handleUploadBtnClick = (e) => {
        e.stopPropagation()
        inputFileRef.current?.click()
    }

    return (
        <Fragment>
            <section
                onDrop={ handleDrop }
                onDragOver={ handleDragOver }
                onDragEnter={ cancelEvent }
                onDragLeave={ handleDragLeave }
                onClick={ handleDropSectionClick }
                className={
                    classNames(
                        'image-uploader',
                        { 'image-uploader--dragging': inDropZone },
                        { 'image-uploader--error': !!errorMessage },
                    )
                }
            >
                { !inDropZone
                    ? !loading
                        ? (
                            <div className="image-uploader__control">
                                <Button
                                    variant={ 'outlined' }
                                    onClick={ handleUploadBtnClick }
                                    fullWidth
                                >
                                    Выберите файл
                                </Button>
                                <div className="image-uploader__control-text">
                                    или перетащите в данную область
                                </div>
                            </div>
                        )
                        : <FileUploaderLoader/>
                    : <i className="icon-s24-plus"/>
                }
                <input
                    type="file"
                    multiple="multiple"
                    className="image-uploader__input-file"
                    ref={ inputFileRef }
                    onChange={ handleFilesChange }
                    accept={ accept }
                />
            </section>
            { errorMessage &&
                <p className="image-uploader__error-message">
                    { `Ошибка загрузки файла: ${ errorMessage }` }
                </p>
            }
        </Fragment>
    )
})

ImageUploader.propTypes = {
    files: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.string,
    ]),
    onFilesChange: PropTypes.func.isRequired,
    accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string), 
    ]),
    maxFileSize: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.oneOf(Object.values(UNITS)),
    }),
    imgDimensionLimits: PropTypes.shape({
        minWidth: PropTypes.number,
        minHeight: PropTypes.number,
        maxWidth: PropTypes.number,
        maxHeight: PropTypes.number,
    }),
    className: PropTypes.string,
    onImageLoaded: PropTypes.func,
}

export default ImageUploader
